.stepperSubDiv {
    background: #172539;
    border-radius: 12px;
    padding: 1.4em;
    color: #63b38f;
    width: 100%;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 50px;
}

.stepperSubDiv h1 {
    font-weight: 500;
    text-align: center;
    color: #70cea2;
}

.stepperBtn {
    cursor: pointer;
    outline: none;
    box-shadow: none;
    border: none;
    background: #172539;
    color: #63b38f;
    border-radius: 12px;
    padding: 0.5em 1.4em;
    font-size: 1.2em;
    font-weight: 500;
    margin: 1.4em;
}

.form-control{
    border-bottom: 2px solid #003461;
}

.php-email-form{
    border: 2px solid #003461;
    border-radius: 10px;
}

.section-title{
    padding: 50px;
    margin-bottom: 50px;
    height: 350px;
    background-image: url("/public/assets/img/cover.jpg");
    background-size: cover;
}

@media (min-width: 1024px) {
    #container{
        width: 50%;
    }

    .section-title{
        padding: 150px;
        margin-bottom: 50px;
        height: 350px;
        background-image: url("/public/assets/img/cover.jpg");
        background-size: cover;
        background-position: right;
        text-align: left;
    }

    .sub-title{
        color: white !important;
        font-family: "Poppins", sans-serif !important;
    }

}

@media (max-width: 1024px) {
    span1 {
        white-space: nowrap;
        font-size: 23px;
    }
    .red{
        font-size: 17px;
    }
}



.stepper{
    margin-bottom: 50px;
    width: 50px;
}


.contact{
    margin-top: 0px !important;
}



.bg-body-tertiary mb-3{
    background-color: red !important;
}

.container-fluid{
    padding-bottom: 20px !important;
    background-color: white;
}


.dark{
    margin-top: 70px !important;
}

#section1{
    padding: 0px !important;
    margin: 0px !important;
}
.card{
    background-color: #ffffff;
    color: #003461;
    height: auto !important;
    min-height: 410px !important;
}

.parallax {
    background-image: url("/public/assets/img/pic5.jpg");
    min-height: 200px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 100px;
}

.big-title{
    background-image: none !important;
    color: white;
    margin-bottom: 0px !important;
    text-align: center;
}

.justify{
    height: 390px;
}
.accordion-list li{
    color:green !important;
}

#about{
    padding: 0px !important;
    margin: 0px !important;
}

.faq-list{
    padding: 0 !important;
}

.btn:hover{
    background-color: #ef6409 !important;
}


.step.progress {
    background: #ef6409;
}

.cfwewB::after{
    background: #ef6409 !important;
}

.body_slider {
    padding: 3em 1em;
    font: normal 40px/50px Arial, sans-serif;
    color: #ef6409;
    background-image: url("/public/assets/img/slider.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 500px;
}



.p_slider{
    font-weight: bolder ;
}

b {
    float: left;
    overflow: hidden;
    position: relative;
    height: 50px;
}
span1 {
    display: inline-block;
    color: #fff;
    position: relative;
    top: 0;
    left: 0;
/*    -webkit-animation: move 8s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-delay: 1s;*/
}

@keyframes move {
    0%  { top: 0px; }
    20% { top: -50px; }
    40% { top: -100px; }
    60% { top: -150px; }
    80% { top: -200px; }
}

.red{
    margin-top: 50px;
    font-weight: bold;
}


@media (max-width: 1024px) {
    .sub-title{
        color: white;
        font-size: 20px;
        padding: 0px !important;
        margin: 0px !important;
        line-height: 30px;
    }

    .body_slider {
        font: normal 40px/50px Arial, sans-serif;
        color: #ef6409;
        background-image: url("/public/assets/img/slider_mobile.jpg") !important;
        background-repeat: repeat !important;
        background-size: cover !important;
        height: 500px;
    }
}

.pic-blog{
    width: 100%;
    float: right;
    padding: 10px;
}












